// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken, getCSRF_Token } from "./localStorageService";
import { API_URL, INITIAL_TOKEN } from "../../config/config";

export const settingApi = createApi({
    reducerPath: "settingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,

        prepareHeaders: (headers, { getState }) => {
            headers.set("token", INITIAL_TOKEN);
            headers.set("Authorization", `Bearer ${getToken()}`);
            headers.set("Accept", "application/json");
            return headers;
        },
    }),
    credentials: "include",
    endpoints: (builder) => ({
        getInstructions: builder.query({
            query: (type) => {
                return {
                    url: `setting/${type}`,
                    method: "GET",
                };
            },
        }),
    }),
});

export const {
    useGetInstructionsQuery
} = settingApi;
