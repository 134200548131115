import { createSlice } from "@reduxjs/toolkit";
import { userAuthApi } from "../services/userAuthApi";
import { userApi } from "../services/userApi";

const initialState = {
  user: null,
  isLoading: true
};

export const authSlice = createSlice({
  name: "authInfo",
  initialState,
  reducers: {
    setUserObject: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userAuthApi.endpoints.loginUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload.data;
        }
      )
      .addMatcher(userAuthApi.endpoints.loginUser.matchRejected, (state) => {
        state.user = null;
      })


      .addMatcher(
        userAuthApi.endpoints.checkRefreshToken.matchFulfilled,
        (state, { payload }) => {
          state.user = payload.data;
        }
      )
      .addMatcher(
        userAuthApi.endpoints.checkRefreshToken.matchRejected,
        (state) => {
          state.user = null;
        }
      )


      .addMatcher(
        userAuthApi.endpoints.getUserProfileData.matchFulfilled,
        (state, { payload }) => {
          state.isLoading = false
          state.user = {
            ...state.user,
            profile: {
              ...state.user.profile,
              ...payload.data
            }
          }
        }
      )
      .addMatcher(
        userAuthApi.endpoints.getUserProfileData.matchRejected,
        (state) => {
          state.user = {
            ...state.user,
            profile: {
              ...state.user.profile,
              coins: 0
            }
          };
        }
      )
      .addMatcher(
        userAuthApi.endpoints.getUserProfileData.matchPending,
        (state) => {
          state.isLoading = true
        }
      )
      .addMatcher(
        userApi.endpoints.updateProfile.matchFulfilled,
        (state, { payload }) => {
          console.log(state, state.user, "in redux");

          // Check the structure of payload to ensure correct data assignment
          state.user = {
            ...state.user,
            profile: {
              ...state.user.profile,
              ...(payload.data || payload) // Use payload.data or payload based on your response structure
            }
          };
        }
      );
  },
});

export const { setAuthInfo, unsetAuthInfo, setUserObject } = authSlice.actions;

export default authSlice.reducer;
