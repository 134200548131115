import React, { useEffect, useState } from "react";
// import { Tab, Nav } from 'react-bootstrap';
import Tabs from "../../Components/Tabs/Tabs";

import styles from "./styles/index.module.css";
import "./styles/index.css";

import {
    Description,
    SubTitle,
} from "../../Components/CustomComponents";
import { Col, Row } from "react-bootstrap";
import TabContent from "./TabContent";
import { useGetInstructionsQuery } from "../../Data/services/setting";
// import { WEB_ROUTES } from '../../constants';

const Instructions = ({ setPage }) => {
    //     privacy-policy
    // terms-and-conditions
    // black-jack-rules
    // baccarat-rules
    // slot-machine-rules
    // prize-pool-rules
    // digit_dream-rules
    const [key, setKey] = useState("black-jack-rules");
    const { data, error, isLoading, isFetching } = useGetInstructionsQuery(key, {
        skip: !key, // Skip the query if key is falsy
    });

    const array = [
        {
            key: "black-jack-rules",
            value: "BlackJack",
            image: "images/game_image_1.png",
        },
        {
            key: "baccarat-rules",
            value: "Baccarat",
            image: "images/game_image_2.png",
        },
        {
            key: "slot-machine-rules",
            value: "Slot Machine",
            image: "images/game_image_3.png",
        },
        {
            key: "prize-pool-rules",
            value: "Prize Pool",
            image: "images/game_image_4.png",
        },
        {
            key: "digit_dream-rules",
            value: "Jackpot",
            image: "images/game_image_5.png",
        },
    ];

    const inlineItemStyles = {
        // background: "#222232",
        // margin: "5px",
        // padding: "10px 20px",
    };


    // black-jack-rules
    // baccarat-rules
    // slot-machine-rules
    // prize-pool-rules
    // digit_dream-rules
    const tabContent = () => {
        const item = array.find(item => item.key === key);
        console.log(item, 'item');

        switch (key) {
            case "black-jack-rules":
                // return "This is Content of 1";
                return <TabContent name={item?.value} text={data?.data?.text} />;
            case "baccarat-rules":
                return <TabContent name={item?.value} text={data?.data?.text} />;
            case "slot-machine-rules":
                return <TabContent name={item?.value} text={data?.data?.text} />;
            case "prize-pool-rules":
                return <TabContent name={item?.value} text={data?.data?.text} />;
            case "digit_dream-rules":
                return <TabContent name={item?.value} text={data?.data?.text} />;

            default:
                return <TabContent name={item?.value} text={"No Data Found"} />;
        }
    };

    return (
        <div className={styles.Instruction}>
            <div className={styles.headerContainer}>
                <div className={styles.titleContainer}>
                    <SubTitle classes="akiraFont" text={"Instructions"} />
                    <Description
                        text={
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                        }
                        classes="mt-1 fs-8"
                    />
                </div>
            </div>

            <Row>
                <Col className="p-2 col-auto">
                    <Tabs
                        tabs={array}
                        current={key}
                        onChange={setKey}
                        itemStyle={inlineItemStyles}
                        containerStyles={{ width: "fit-content" }}
                    />
                </Col>

                <Col className={`p-2 ${styles.content}`}>
                    {
                        tabContent()
                    }

                </Col>
            </Row>
        </div>
    );
};

export default Instructions;
