import React from 'react'

import styles from "./styles/index.module.css";
import "./styles/index.css";

const TabContent = ({ name, text }) => {
    return (
        <div>
            <p className={`bigeroverFont fs-4 m-0 ${styles.contentSubHeading}`}>
                Rules
            </p>
            <p className="bigeroverFont fs-1 ">{name}</p>
            <div className={styles.contentParagraph}>
                <p>
                    {text}
                </p>
            </div>
        </div>
    )
}

export default TabContent
