// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken, getCSRF_Token } from "./localStorageService";
import { API_URL, INITIAL_TOKEN } from "../../config/config";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,

    prepareHeaders: (headers, { getState }) => {
      headers.set("token", INITIAL_TOKEN);
      headers.set("Authorization", `Bearer ${getToken()}`);
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  credentials: "include",
  endpoints: (builder) => ({
    updateProfile: builder.mutation({
      query: (data) => {
        return {
          url: "update_profile",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useUpdateProfileMutation,
} = userApi;
