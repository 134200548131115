import React, { useState } from "react";
import styles from "./styles/index.module.css";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement, CardElement } from '@stripe/react-stripe-js';
import { Col, Form, Row } from "react-bootstrap";
import SelectFieldComponent from "../../Components/CustomComponents/SelectFieldComponent";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { usePaymentMethodMutation } from "../../Data/services/Payment";
import { Button, ThemeSpinner } from "../../Components/CustomComponents";
import BeatLoader from "react-spinners/BeatLoader";

const CardPaymentMethod = () => {
    const [name, setName] = useState(null)
    const [loading, setLoading] = useState(false);
    const [paymentApi, { isError, isLoading, isSuccess }] = usePaymentMethodMutation();


    const stripe = useStripe();
    const elements = useElements();

    const onSubmit = async (event) => {
        setLoading(true)
        event.preventDefault()
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const card = elements.getElement(CardElement);

        if (card == null) {
            return;
        }

        // Use your card Element with other Stripe.js APIs
        // const { error, paymentMethod } = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card,
        // });
        const { token, error } = await stripe.createToken(
            elements.getElement(CardElement)
        );


        if (error) {
            Toast("Something went wrong.", "error");
            setLoading(false)
        } else {
            let payLoad = {
                payment_method_id: token?.id,
                name: name
            }
            try {
                const response = await paymentApi(payLoad);
                if (response?.error?.data || isError) {
                    Toast(response?.error?.data?.message, "error");
                }
                setLoading(false)
                SuccessToast(response?.data?.message);
            }
            catch (error) {
                setLoading(false)
                Toast("Something went wrong.", "error");
            }
        }
    };

    return (
        <Row className="mb-3">
            <Col sm={12} md={9} className="rounded-4 p-4" style={{ backgroundColor: "#202127" }}>
                <Row>
                    <Col sm={12} md={12} className="mb-2">
                        <Form.Control
                            type="text"
                            placeholder="Enter cardholder's name"
                            value={name}
                            className={styles.Input}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Col>
                    <Col sm={12} md={6} className="mb-2 mt-5">
                        {/* <Form.Label className={styles.CardDetails}>Card Details</Form.Label> */}
                        <CardElement
                            options={{
                                hidePostalCode: true,
                                style: {
                                    base: {
                                        iconColor: '#c4f0ff',
                                        color: '#fff',
                                        fontWeight: '500',
                                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                                        fontSize: '16px',
                                        fontSmoothing: 'antialiased',
                                        ':-webkit-autofill': {
                                            color: '#fce883',
                                        },
                                        '::placeholder': {
                                            color: '#87BBFD',
                                        },
                                    },
                                    invalid: {
                                        iconColor: '#FFC7EE',
                                        color: '#FFC7EE',
                                    },
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Button text={loading ? <BeatLoader size={10} /> : "ADD CARD"} type="submit" disabled={loading} classes={`mt-5 d-flex align-items-center justify-content-center ms-auto ${loading ? "bg-dark-subtle" : ''} ${styles.addCard}`} onClick={onSubmit}>
                </Button>
            </Col>
        </Row>
    );
};

export default CardPaymentMethod;
