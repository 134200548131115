import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

/**Import Assets */
import styles from "./styles/ProtectedLayout.module.scss";
// import cssStyles from './styles/notification.module.css';

/**Import Custom Component */
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import Navbar from "./Navbar";

import { useDispatch, useSelector } from "react-redux";
import FilterModal from "../Modals/FilterModal";
import NotificationModal from "../Modals/NotificationModal";
// Icons
import { IoDocumentText } from "react-icons/io5";
import {
  MdCurrencyExchange,
  MdDashboard,
  MdOutlinePayment,
} from "react-icons/md";
import { TbLogout2 } from "react-icons/tb";
import { TiMessages } from "react-icons/ti";
import { setUserObject } from "../../Data/Features/authSlice";
import { unsetUserInfo } from "../../Data/Features/userSlice";
import { removeToken } from "../../Data/services/localStorageService";
import { useGetUserProfileMutation } from "../../Data/services/userAuthApi";
import Toast from "../../Hooks/useToast";
import LogoutModal from "../Modals/LogoutModal";

export default function ProtectedLayout({
  isNavBar = false,
  isSearchBar,
  isSettingButton,
  selectedRoute,
  children,
}) {
  const { user, isLoading } = useSelector((state) => state.authInfo);
  const [avatar, setAvatar] = useState(-1)
  console.log(avatar, 'hsh');

  const [collapsed, setCollapsed] = useState(window.innerWidth > 991);
  const [notificationModal, setNotificationModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [text, setText] = useState("...")
  const [logoutModal, setLogoutModal] = useState(false);

  const [getUserProfile, { }] = useGetUserProfileMutation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleResize = () => {
    if (window.innerWidth < 991) {
      handleMenuClose();
    }
  };

  const handleLogoutModalOpen = () => {
    setLogoutModal(true);
  };

  const handleLogoutModalClose = () => {
    setLogoutModal(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      let result = await getUserProfile();
      if (result.data.statusCode == 200) {
        dispatch(setUserObject(result.data.data));
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // todo: menu
  const items = [
    {
      label: "Games",
      icon: <MdDashboard />,
    },
    {
      label: "Instructions",
      icon: <IoDocumentText />,
    },
    {
      label: "Payment Methods",
      icon: <MdOutlinePayment />,
    },
    {
      label: "Currency",
      icon: <MdCurrencyExchange />,
    },
    {
      label: "Customer Support",
      icon: <TiMessages />,
    },
  ];

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClose = () => {
    setCollapsed(false);
  };

  const handleViewProfile = () => {
    navigate(baseRoute(WEB_ROUTES.PROFILE));
    if (collapsed && window.innerWidth < 991) {
      handleMenuClose();
    }
  };

  const handleLogout = () => {
    // dispatch(updateLogin(false))

    try {
      removeToken();

      dispatch(
        unsetUserInfo({
          token: "",
          email: "",
          name: "",
          isLoggedIn: false,
          keepLoggedIn: "",
        })
      );
      window.location.reload();

      Toast("Logged Out Successfully", "success");
    } catch {
      Toast("An error occurred during log out", "error");
    }
  };

  const handleNotificationModalClose = () => {
    setNotificationModal(false);
  };

  const handleNotificationModalOpen = () => {
    // setNotificationModal(true)
  };

  const handleFilterModalClose = () => {
    setFilterModal(false);
  };

  const handleFilterModalOpen = () => {
    setFilterModal(true);
  };

  useEffect(() => {
    if (user?.image_url === "" || user?.image_url === undefined || user?.image_url === null) {
      switch (user?.avatar_id) {
        case -1:
          setAvatar("/images/avatars/avatar_placeholder.png")
          break;
        case 0:
          setAvatar("/images/avatars/avatar_1.png")
          break;
        case 1:
          setAvatar("/images/avatars/avatar_2.png")
          break;
        case 2:
          setAvatar("/images/avatars/avatar_3.png")
          break;
        case 3:
          setAvatar("/images/avatars/avatar_4.png")
          break;
        case 4:
          setAvatar("/images/avatars/avatar_5.png")
          break;
        case 5:
          setAvatar("/images/avatars/avatar_6.png")
          break;
        case 6:
          setAvatar("/images/avatars/avatar_7.png")
          break;
        case 7:
          setAvatar("/images/avatars/avatar_8.png")
          break;
        case 8:
          setAvatar("/images/avatars/avatar_9.png")
          break;
        case 9:
          setAvatar("/images/avatars/avatar_10.png")
          break;
        default:
          setAvatar(`/images/avatars/avatar_placeholder.png`)
          break;
      }
    }
  }, [user])

  useEffect(() => {
    if (user?.full_name) {
      setText(user?.full_name)
    }
  }, [user, user?.full_name])

  return (
    <div className={styles.ProtectedLayout}>
      <div
        className={collapsed ? styles.background : ""}
        onClick={handleMenuClose}
      ></div>
      <div
        className={`${styles.menu} ${collapsed ? `${styles.collapsed} visible` : styles.notCollapsed
          }`}
        id="sider"
      >
        <div
          className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ""}`}
          to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}
        >
          <img
            src={
              (user?.image_url !== "" && user?.image_url !== null)
                ? user?.image_url : avatar
            }
            alt="User Profile"
            className={
              collapsed ? styles.imageCollapsed : styles.imageNotCollapsed
            }
          />
          <p className={styles.name}>{text}</p>
          <button
            className={`${styles.profileButton} ${selectedRoute === 5 ? "bg" : ""
              }`}
            onClick={handleViewProfile}
          >
            View Profile
          </button>
        </div>

        {/* <div className={`${styles.drawer} ${collapsed ? styles.collapsed : ''}`}>

                    <span onClick={handleToggle}>
                        <BiLogOut />
                    </span>
                </div> */}

        {items.map(({ icon, label }, index) => {
          return (
            <Link
              to={`${process.env.REACT_APP_DOMAIN_DIR}/${label
                .toLowerCase()
                .replace(/[^A-Z0-9]/gi, "-")}`}
              onClick={
                collapsed && window.innerWidth < 991 ? handleMenuClose : ""
              }
              className={`${styles.item} ${index === selectedRoute ? styles.active : ""
                }`}
              key={index}
            >
              <span style={{ fontSize: "20px", display: "flex" }}>
                {" "}
                {icon}{" "}
              </span>
              {collapsed ? <p>{label}</p> : ""}
            </Link>
          );
        })}

        <Link
          className={styles.item}
          // onClick={handleLogout}
          onClick={handleLogoutModalOpen}
        // onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())}
        >
          {/* <img src={logout} alt="Icon1" width={20} height={20} /> */}
          <span style={{ fontSize: "20px", display: "flex" }}>
            {" "}
            <TbLogout2 />{" "}
          </span>
          {collapsed ? <p>Logout</p> : ""}
        </Link>
      </div>
      <div className={styles.routeContent}>
        {logoutModal ? (
          <LogoutModal
            handleLogoutModalClose={handleLogoutModalClose}
            data={""}
            onLogout={handleLogout}
          />
        ) : null}

        {isNavBar ? (
          <Navbar
            isSearchBar={isSearchBar}
            isSettingButton={isSettingButton}
            handleToggle={handleToggle}
            handleNotificationModalOpen={handleNotificationModalOpen}
            handleFilterModalOpen={handleFilterModalOpen}
          />
        ) : (
          ""
        )}
        <div className={styles.content}>
          {notificationModal ? (
            <NotificationModal
              handleNotificationModalClose={handleNotificationModalClose}
            />
          ) : (
            ""
          )}
          {filterModal ? (
            <FilterModal handleFilterModalClose={handleFilterModalClose} />
          ) : (
            ""
          )}

          {children}
        </div>
      </div>
    </div>
  );
}
