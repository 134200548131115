import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/index.module.css";
import {
  Button,
  Description,
  Input,
  SubTitle,
} from "../../Components/CustomComponents";
import { Col, Row } from "react-bootstrap";

// icon
import { BiSolidEdit } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { MdEdit } from "react-icons/md";

import SelectFieldComponent from "../../Components/CustomComponents/SelectFieldComponent";
import { ROUTES } from "./Route";
import { useNavigate } from "react-router-dom";
import { useChangeUserPasswordMutation } from "../../Data/services/userAuthApi";
import Toast from "../../Hooks/useToast";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateProfileMutation } from "../../Data/services/userApi";
import { setUserObject } from "../../Data/Features/authSlice";

const Profile = ({ setPage }) => {
  const dispatch = useDispatch()
  const { user, isLoading } = useSelector((state) => state.authInfo);
  const [avatar, setAvatar] = useState(-1)
  const [newAvatar, setNewAvatar] = useState(null)
  console.log(newAvatar, 'aaa');

  const [oldPassword, setOldPassword] = useState(null)
  const [newPassword, setNewPassword] = useState(null)
  const [cnfrmPassword, setCnfrmPassword] = useState(null)
  const [loading, setLoading] = useState(false)
  const [customUpload, setCustomUpload] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [changePassword, { isError: isPasswordError, isLoading: isPasswordLoading, isSuccess: isPasswordSuccess }] =
    useChangeUserPasswordMutation();
  const [updateProfile, { isError: isUpdateError, isLoading: isUpdateLoading, isSuccess: isUpdateSuccess }] =
    useUpdateProfileMutation()

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCustomUpload(true)
      const imageUrl = URL.createObjectURL(file); // Create a preview URL
      setNewAvatar(file);
    }
  };

  const [isEditProfile, setIsEditProfile] = useState(false);
  const navigate = useNavigate();

  const [text, setText] = useState("..."); // Initial text state
  const [editMode, setEditMode] = useState(false); // Edit mode state

  const handleEdit = () => {
    setEditMode(true); // Enable edit mode
  };

  const handleSave = () => {
    // Save the edited text or perform any necessary actions
    setEditMode(false); // Disable edit mode
  };

  const handleChange = (event) => {
    setText(event.target.value); // Update text as the user types
  };

  const [selectedCountry, setSelectedCountry] = useState("Canada");
  const countryList = ["UK", "USA", "Canada", "Turkey", "Spain"];

  const avatarList = [
    { src: "images/avatars/avatar_1.png", id: 0 },
    { src: "images/avatars/avatar_2.png", id: 1 },
    { src: "images/avatars/avatar_3.png", id: 2 },
    { src: "images/avatars/avatar_4.png", id: 3 },
    { src: "images/avatars/avatar_5.png", id: 4 },
    { src: "images/avatars/avatar_6.png", id: 5 },
    { src: "images/avatars/avatar_7.png", id: 6 },
    { src: "images/avatars/avatar_8.png", id: 7 },
    { src: "images/avatars/avatar_9.png", id: 8 },
    { src: "images/avatars/avatar_10.png", id: 9 },
  ];

  const handleCountryChange = (value) => setSelectedCountry(value);
  const handleEditProfile = (value) => setIsEditProfile(value);

  const handleSubmit = async () => {
    setUpdateLoading(true);

    const formData = new FormData();
    formData.append("full_name", text);
    formData.append("avatar_id", customUpload ? null : newAvatar?.id);

    if (customUpload && newAvatar) {
      formData.append("image_url", newAvatar);
    }

    try {
      const res = await updateProfile(formData).unwrap();

      if (res?.error) {
        Toast(res?.error?.data?.errors, "error");
      } else {
        Toast(res?.data?.message, "success");
        console.log(res, "profile response");
        dispatch(setUserObject(res?.data));
        handleEditProfile(false);
      }
    } catch (err) {
      Toast("Something went wrong", "error");
    } finally {
      setNewAvatar(null);
      setEditMode(false);
      setUpdateLoading(false);
    }
  };

  const handleSavePassword = async () => {
    setLoading(true)
    const payload = {
      current_password: oldPassword,
      new_password: newPassword,
      confirmed_password: cnfrmPassword,
    };
    try {
      let res = await changePassword(payload)

      if (res?.error) {
        Toast(res?.error?.data?.errors, "error")
      }
      Toast(res?.data?.message, "success")
      handleEditProfile(false);
    }
    catch (err) {
      Toast("Something went wrong", "error")
    }
    finally {
      setLoading(false)
    }
  };

  const gotoCurrencyScreen = () => {
    // setPage(ROUTES.CURRENCY);
    navigate('/' + ROUTES.CURRENCY);

    // alert("Goto Currency Screen");
  };
  useEffect(() => {
    if (user?.image_url === "" || user?.image_url === undefined || user?.image_url === null) {
      switch (user?.avatar_id) {
        case -1:
          setAvatar("/images/avatars/avatar_placeholder.png")
          break;
        case 0:
          setAvatar("/images/avatars/avatar_1.png")
          break;
        case 1:
          setAvatar("/images/avatars/avatar_2.png")
          break;
        case 2:
          setAvatar("/images/avatars/avatar_3.png")
          break;
        case 3:
          setAvatar("/images/avatars/avatar_4.png")
          break;
        case 4:
          setAvatar("/images/avatars/avatar_5.png")
          break;
        case 5:
          setAvatar("/images/avatars/avatar_6.png")
          break;
        case 6:
          setAvatar("/images/avatars/avatar_7.png")
          break;
        case 7:
          setAvatar("/images/avatars/avatar_8.png")
          break;
        case 8:
          setAvatar("/images/avatars/avatar_9.png")
          break;
        case 9:
          setAvatar("/images/avatars/avatar_10.png")
          break;
        default:
          setAvatar(`/images/avatar_placeholder.png`)
          break;
      }
    }
  }, [user])
  useEffect(() => {
    if (user?.full_name) {
      setText(user?.full_name)
    }
  }, [user])

  return (
    <div className={styles.Profile}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle classes="akiraFont" text={"Profile"} />
          <Description
            text={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            }
            classes="mt-1 fs-8"
          />
        </div>
      </div>

      <Row className={` w-100 mt-2 mb-2`}>
        <Col className={`mt-2 mb-2`} md={6} sm={12}>
          <div className={`${styles.userContainer} mt-4 mb-3`}>
            <div className="d-flex flex-column justify-content-around align-items-center">
              <img
                src={
                  (user?.image_url !== "" && user?.image_url !== null && newAvatar === null)
                    ? user?.image_url
                    : (newAvatar && newAvatar?.src) ? newAvatar?.src : (newAvatar && customUpload) ? URL?.createObjectURL(newAvatar) : avatar
                }
                alt="User Profile"
                className={styles.image}
              />
              {newAvatar &&
                <div
                  className={styles.uploadBtn}
                  onClick={() => {
                    setNewAvatar(null);
                    setCustomUpload(false);
                  }}
                >
                  Reset
                </div>
              }
              <div>
                {!newAvatar && (
                  <div
                    className={styles.uploadBtn}
                    onClick={handleUploadClick}
                  >
                    Upload Photo
                  </div>
                )}

                {/* Hidden file input */}
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept="image/*" // Limit to image files
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-around align-items-center">
              <div className={styles.nameContainer}>
                <div className={styles.userName}>
                  {editMode ? (
                    <Input
                      type="text"
                      classes="mb-2 fw-bold gilroyFont"
                      value={text}
                      onChange={handleChange}
                    />
                  ) : (
                    <div>{text}</div>
                  )}
                </div>
                {editMode ? (
                  ""
                ) : (
                  <BiSolidEdit color="white" size={20} onClick={handleEdit} />
                )}
              </div>

              <div className={styles.showCoins}>
                <div className={`${styles.dc} bigeroverFont`}>DC</div>

                <div className={` d-flex flex-column text-white bigeroverFont`}>
                  <p className="m-0 fs-1" style={{ lineHeight: 1 }}>
                    {" "}
                    500{" "}
                  </p>
                  <p className="m-0"> Dubb Coins </p>
                </div>

                <div className={styles.plusIcons} onClick={gotoCurrencyScreen}>
                  <FiPlus color="white" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="d-flex align-items-center mb-2">
              <p className="fs-5 fw-bold m-0 pe-2">Profile Information</p>
              <MdEdit color="#F1C256" onClick={() => handleEditProfile(true)} />
            </div>

            <p className="fw-bold m-0 mb-1 " style={{ color: "#F1C256" }}>
              Email Address
            </p>

            <Input
              placeholder="Abram@Example.com"
              type="email"
              classes="mb-2 fw-bold gilroyFont"
              // fontClass="gilroyFont"
              disabled={true}
              value={user?.email}
            />

            <p className=" fw-bold m-0 mb-1 " style={{ color: "#F1C256" }}>
              Country
            </p>

            <SelectFieldComponent
              firstOption={
                user?.country ? user?.country : "Select Your Country"
              }
              optionsList={countryList}
              onSelectChange={handleCountryChange}
              disabled={isEditProfile ? false : true}
              className={styles.select}
            />

            <Button
              text="Save Changes"
              classes={`mt-3 mb-2 bg p-3 ${loading ? "bg-dark-subtle" : ''}`}
              onClick={handleSubmit}
              disabled={updateLoading}

            />
          </div>
        </Col>
        <Col className={`mt-2 mb-2`} md={6} sm={12}>
          <p className="fs-5 fw-bold m-0 pe-2">Avatars</p>

          <Row className={` w-100 mt-2 mb-2`}>
            {avatarList.map((avatar) => (
              <Col
                className={`mt-2 mb-2 ${styles.avatarCol}`}
                xs={6}
                sm={4}
                md={4}
                lg={3}
              >
                <img
                  src={avatar.src}
                  alt={avatar.id}
                  className={styles.avatarImg}
                  onClick={() => { setNewAvatar(avatar); setCustomUpload(false) }}
                  style={{
                    cursor: 'pointer'
                  }}
                />
              </Col>
            ))}
          </Row>

          <div>
            <div className="d-flex align-items-center mb-2">
              <p className="fs-5 fw-bold m-0 pe-2">Change Pasword</p>
            </div>

            <p className=" fw-bold m-0 mb-1 " style={{ color: "#F1C256" }}>
              Old Password
            </p>
            <Input
              placeholder="*******"
              type="password"
              classes="mb-2"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              disabled={isEditProfile ? false : true}
            />

            <p className=" fw-bold m-0 mb-1" style={{ color: "#F1C256" }}>
              New Password
            </p>
            <Input
              placeholder="*******"
              type="password"
              classes="mb-2"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              disabled={isEditProfile ? false : true}
            />

            <p className=" fw-bold m-0 mb-1" style={{ color: "#F1C256" }}>
              Confirm Password
            </p>
            <Input
              placeholder="*******"
              type="password"
              classes="mb-2"
              value={cnfrmPassword}
              onChange={(e) => setCnfrmPassword(e.target.value)}
              disabled={isEditProfile ? false : true}
            />

            <Button
              text={loading ? <BeatLoader size={10} /> : "Change Password"}
              classes={`mt-3 mb-2 bg p-3 ${loading ? "bg-dark-subtle" : ''}`}
              onClick={handleSavePassword}
              disabled={loading}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
