import React, { useEffect, useState } from "react";
import styles from "./styles/index.module.css";
import './styles/index.css';

import {
  Button,
  Description,
  SubTitle,
} from "../../Components/CustomComponents";
import { Col, Container, Form, Row } from "react-bootstrap";

// icon
import { FiPlus } from "react-icons/fi";
import CustomRadioButton from "../../Components/CustomComponents/CustomRadioButton";
import { MdDeleteOutline } from "react-icons/md";
import SelectFieldComponent from "../../Components/CustomComponents/SelectFieldComponent";

// date
import dayjs from 'dayjs';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardPaymentMethod from "./Element";
import { useDeletePaymentMethodMutation, useGetPaymentMethodQuery } from "../../Data/services/Payment";
import BeatLoader from "react-spinners/BeatLoader";
import Toast, { SuccessToast } from "../../Hooks/useToast";

const PaymentMethod = ({ setPage }) => {
  const [paymentType, setPaymentType] = useState("");
  const [expiryDate, setExpiryDate] = useState();
  const [deletedId, setDeletedId] = useState(null)
  const stripePromise = loadStripe('pk_test_51NPUFfI5pBhNo0COy4LFXhLHSip0uzNIGYHFaO0g6PqfUFEGavk4PJnYQj78qY5FtxFU4rpidkYv9Bgo0N5iqQD5007f9K3mHQ');
  const { data, error, isLoading, isFetching } = useGetPaymentMethodQuery();
  const [deleteCard, { isError, isLoading: isDeleteLoading, isSuccess }] = useDeletePaymentMethodMutation()
  const handleRadioChange = (value) => {
    setPaymentType(value); // Update the state with the selected value
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf('day');
  };

  const handleRemoveCard = async (item) => {
    setDeletedId(item?.slug)
    try {
      let response = await deleteCard(item?.slug);
      SuccessToast(response?.data?.message)
    }
    catch (err) {
      Toast("Something went wrong", "error")
    }
  }
  return (
    <div className={styles.Payment}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle classes="akiraFont" text={"Payment Methods"} />
          <Description
            text={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            }
            classes="mt-1 fs-8"
          />
        </div>
      </div>

      <Row className="my-5">
        <Col sm={12} md={3}>
          <div
            className={`d-flex w-100 flex-row justify-content-evenly rounded-4  mb-3 ${styles.paymentTypeCard} ${styles.active}`}
          >
            <img
              src="images/visa.png"
              alt="master card logo"
              width={50}
              className={`object-fit-contain`}
            />
            <img
              src="images/mastercard.png"
              alt="master card logo"
              width={50}
              className={`object-fit-contain`}
            />
          </div>

          <div
            className={`d-flex w-100 flex-row justify-content-evenly rounded-4 mb-3 ${styles.paymentTypeCard}`}
          >
            <img
              src="images/paypal.png"
              alt="master card logo"
              width={50}
              className={`object-fit-contain`}
            />
          </div>
        </Col>
        <Col sm={12} md={9}>
          <SubTitle classes="fw-bold fs-5" text={"Card Details"} />
          <Description
            classes="mt-1 fs-8 mb-4"
            text={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            }
          />

          {isLoading ? (
            <BeatLoader size={30} color="white" />
          ) : (
            data && data?.data?.length > 0 ? data?.data?.map((dt) => (
              <Row key={dt?.slug}>
                <Col sm={12} md={6}>
                  <div className={`w-100 rounded-4 p-4 mb-3 ${styles.paymentCard} ${styles.active}`}>
                    <p className="mb-3 bigeroverFont fs-4 lc-1">{dt?.name}</p>
                    <p className="mb-2 bigeroverFont fs-5 lc-1">**** **** **** {dt?.last_four}</p>
                    <p className="m-0">{`${dt?.expiry_month}/${dt?.expiry_year}`}</p>
                  </div>
                </Col>
                <Col sm={12} md={3} className="align-content-center">
                  {isDeleteLoading && deletedId === dt?.slug ? (
                    <BeatLoader size={20} color="white" />
                  ) : (
                    <div
                      className="px-3 py-2 my-3 rounded-4 text-center d-flex align-items-center justify-content-center"
                      style={{ backgroundColor: "#202127", color: "white" }}
                      onClick={() => handleRemoveCard(dt)}
                      role="button"
                    >
                      <span>
                        <MdDeleteOutline /> Remove card
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
            )) : (
              <span className={styles.spanNoDataFound}>No Card Available</span>
            ))
          }

          <SubTitle classes="fw-bold fs-5 mt-4" text={"Fill form Below"} />
          <Description
            classes="mt-1 fs-8 mb-4"
            text={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            }
          />
          <Elements stripe={stripePromise}>
            <CardPaymentMethod />
          </Elements>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentMethod;
