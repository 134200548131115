import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  name: "",
  permissions: [], // this is direct permission | permissions.map((permiison)=>{permission.name})
  roles: [], // this is roles and permission | roles -> role.name | roles.permissions.map((permiison)=>{permission.name})
  users: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.permissions = action.payload.permissions;
      state.roles = action.payload.roles;
    },
    unsetUserInfo: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.permissions = action.payload.permissions;
      state.roles = action.payload.roles;
    },
    updateUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});


export const { setUserInfo, unsetUserInfo, updateUsers } = userSlice.actions;

export default userSlice.reducer;
